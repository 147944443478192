.section-projects{
    display: flex;
    justify-content: center;
    align-items: center;
  
}

.project-wrapper{
width: 80%;
margin: 0 auto;
display:grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
grid-gap: 2rem;



}
.project-container{
    width: 20rem;
    height: 20rem;
    overflow: hidden;
    position: relative;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $primary-color;
    color:  #e0ebe8;
}

.project-empty{
    width: 20rem;
    height: 20rem;
    overflow: hidden;
    position: relative;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $primary-color;
    color:  #e0ebe8;
    // opacity:0.5;
   
}

.project-container img{
    width: 100%;
    transition: all .5s;
}
.project-caption {
    position: absolute;
    bottom:0;
    left:0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.25s;
    transform: translateY(-20px);
    opacity: 0;
  
    i {
        font-size: 5rem;
    }
}

.project-links{
display: flex;
justify-content: space-around;
align-items: center;
width: 100%;
margin-top: 3rem;
}
.project-container:before{
    content:"";
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    opacity:0.5;
    transform: translateY(100%);
    transition: all 0.25s;
transition-delay: 0.25s;
}
.project-container:hover:before{
 transform: translateY(0%);
 transition-delay: 0s;
   
}
.project-container:hover .project-caption{
opacity: 1;
transform: translateY(0);
transition-delay: 0.3s;
}




.project-empty:before{
    content:"";
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    opacity:0.5;
    transform: translateY(100%);
    transition: all 0.25s;
transition-delay: 0.25s;
}
.project-empty:hover:before{
 transform: translateY(0%);
 transition-delay: 0s;
   
}
.project-empty:hover .project-caption{
opacity: 1;
transform: translateY(0);
transition-delay: 0.3s;
}



