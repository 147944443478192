.section-home{
    // color: #7014e8;
    display: flex;
    justify-content: center;;
    align-items: center;
}
.home-wrapper{
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;;
    align-items: center;
}
.about-highlights{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    text-align: center;
}
.highlight-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    color:#333;
    
}

.icon-container{
    margin-bottom: 2rem;

}
.windows-wrapper{
    border: 2px solid #7014e8;
   
}
.windows-wrapper:before{
    background-color: #7014e8;
}
.windows-wrapper:after{
    border: 2px dashed #6772e5;
}
// #61dafb

.node-wrapper{
    border: 2px solid #026e00;
}
.node-wrapper:before{
    background-color: #026e00;
}
.node-wrapper:after{
    border: 2px dashed #06ac03;
}
.react-wrapper{
    border: 2px solid #282c34;
    i{
        color:#61dafb;
    }
   
}
.react-wrapper:before{
    background-color: #282c34;
}
.react-wrapper:after{
    border: 2px dashed #555a64;
}


.sql-wrapper{
    border: 2px solid #336791;
}
.sql-wrapper:before{
    background-color: #336791;
}
.sql-wrapper:after{
    border: 2px dashed #336791;
}


.icon-wrapper {
    width: 12rem;
    height: 12rem;
   
    border-radius: 50%;
    transition: all 0.5s;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
   
}
.icon-wrapper:before{
    content: '';
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
   
    border-radius: 50%;
    z-index: -1;
    transition: all 0.5s;
    transform: scale(0.8);

}
.icon-wrapper:after{
    content: '';
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 50%;
    z-index: -1;
    transition: all 0.5s;
    
    
    animation: rotating 10s linear infinite;

}
.icons {
    font-size: 4.8rem;
    color: #e0ebe8;
}

@keyframes rotating {
    0% {transform: scale(0.92) rotate(0deg)} 
    100% {transform: scale(0.92) rotate(360deg)}
 }

 .highlight-text{
     font-size: 1.6rem;
     color: $primary-heading
     
 }
 

@media screen and (max-width: 700px){
    .about-highlights{
        grid-template-columns: 1fr 1fr;
    }
    .highlight-text{
        font-size: 1.4rem;
        
    }
}
@media screen and (max-width: 450px){
    .about-highlights{
        grid-template-columns: 1fr;
    }
    .highlight-text{
        font-size: 1.3rem;
    }
}