
.coming-soon{
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 3rem;
    line-height: 3rem;
    letter-spacing: 8px;
    text-shadow: 0 1px 0 #efefef,0 1px 0 #efefef,0 1px 0 #efefef,0 1px 0 #efefef, 0 10px 5px rgba(0,0,0,.1);
    animation: float 3s linear infinite;
    animation-direction: alternate;
    
}

@keyframes float{
    from{transform: rotate(5deg);}
    to{transform: rotate(-5deg);}
}