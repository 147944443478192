$primary-color : #1e836c;
$secondary-color: #45b29a;
$trinary-color: #c5ded7; // footer background

$primary-text: #e0ebe8;

$primary-heading: #1e836c;
$primary-para: #545454;

$placholder-color:#1e836b65;
$navbar-border: rgb(239, 178, 30);
